import { BrowserRouter, Routes, Route } from "react-router-dom";

import LandingPage from "./components/LandingPage";
import Register from "./components/Register";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import CreateDashboard from "./components/CreateDashboard";

import EditPoints from "./components/EditPoints";
import ProtectedRoute from "./utils/ProtectedRoute";

import "./App.css";
import BoardDashboard from "./components/BoardDashboard";
import EditBoard from "./components/EditBoard";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<LandingPage />} />
					<Route exact path="/register" element={<Register />} />
					<Route exact path="/login" element={<Login />} />
					<Route element={<ProtectedRoute />}>
						<Route exact path="/dashboard" element={<Dashboard />} />
						<Route exact path="/boards/:boardId" element={<BoardDashboard />} />
						<Route
							exact
							path="/create-dashboard"
							element={<CreateDashboard />}
						/>
						<Route
							exact
							path="/edit-point/:point_id"
							element={<EditPoints />}
						/>
						<Route exact path="/edit-board/:board_id" element={<EditBoard />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
