import React from "react";
import { Link } from "react-router-dom";

import Logo from "../images/icons/favicon.png";
import LogoutIcon from "../images/icons/logout-icon.png";

const Navigation = () => {
	const greeting = localStorage.getItem("message");
	return (
		<div className="navigation">
			<Link to="/" className="logo-and-welcome-message">
				<img
					className="navigation-logo"
					alt="Hogwarts-Points-Tracker-Logo"
					src={Logo}
				/>
				<p className="welcome-message">{greeting}</p>
			</Link>

			<Link
				className="sign-up-button"
				to="/"
				onClick={() => localStorage.clear()}
			>
				<div className="logout-button">
					&nbsp; Logout
					<img
						className="logout-icon"
						alt="Hogwarts-Points-Tracker-Logo"
						src={LogoutIcon}
					/>
				</div>
			</Link>
		</div>
	);
};

export default Navigation;
