import React, { useState, useEffect } from "react";
import { Progress } from "semantic-ui-react";
import axiosWithAuth from "../utils/axiosWithAuth";

function PointsProgress({ board_id }) {
	const [totalPoints, setTotalPoints] = useState({
		gryffindor: 0,
		slytherin: 0,
		hufflepuff: 0,
		ravenclaw: 0,
	});

	const [boardPoints, setBoardPoints] = useState(0);

	const Gryffindor = totalPoints.gryffindor;
	const Slytherin = totalPoints.slytherin;
	const Hufflepuff = totalPoints.hufflepuff;
	const Ravenclaw = totalPoints.ravenclaw;

	useEffect(() => {
		loadPoints(board_id);
	}, [board_id]);

	function loadPoints(board_id) {
		axiosWithAuth()
			.get(`/api/rewards/board/${board_id}/points`)
			.then((res) => {
				const points = res.data.points || [];
				const totalPoints = points.reduce((acc, point) => {
					const house = point.house.toLowerCase();
					acc[house] = (acc[house] || 0) + point.amount;
					return acc;
				}, {});

				const boardTotalPoints = Object.values(totalPoints).reduce(
					(total, value) => total + value,
					0
				);
				setTotalPoints(totalPoints);
				setBoardPoints(boardTotalPoints);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<div>
			<Progress
				inverted
				indicating
				color="red"
				size="medium"
				progress="value"
				total={boardPoints}
				value={Gryffindor}
			/>
			<Progress
				inverted
				indicating
				color="green"
				size="medium"
				progress="value"
				total={boardPoints}
				value={Slytherin}
			/>
			<Progress
				inverted
				indicating
				color="yellow"
				size="medium"
				progress="value"
				total={boardPoints}
				value={Hufflepuff}
			/>
			<Progress
				inverted
				indicating
				color="blue"
				size="medium"
				progress="value"
				total={boardPoints}
				value={Ravenclaw}
			/>
		</div>
	);
}

export default PointsProgress;
