import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Button, Icon, Form } from "semantic-ui-react";
import { TextArea } from "semantic-ui-react";

import Hogwarts from "../images/board/hover/icon-2.png";
import CreateBoardSuccessMessage from "../utils/ErrorHandling/CreateBoardSuccessMessage";
import CreateBoardErrorMessage from "../utils/ErrorHandling/CreateBoardErrorMessage";
import Navigation from "./Navigation";
import Footer from "./Footer";

export default function EditDashboard() {
	const navigate = useNavigate();
	const id = useParams().board_id;
	const [dashboard, setDashboard] = useState({
		id: "",
		name: "",
		description: "",
	});
	const [alert, setAlert] = useState({
		alertMessage: "",
	});

	useEffect(() => {
		axiosWithAuth()
			.get(`/api/dashboards/board/${id}`)
			.then((response) => {
				setDashboard(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id]);

	const handleChanges = (e) => {
		setDashboard({
			...dashboard,
			[e.target.name]: e.target.value,
		});
	};

	const onDelete = () => {
		if (window.confirm("Are you sure you want to delete this dashboard?")) {
			axiosWithAuth()
				.delete(`/api/dashboards/board/${id}`)
				.then((response) => {
					navigate("/dashboard");
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const updatedDashboard = {
			...dashboard,
		};
		axiosWithAuth()
			.put(`/api/dashboards/board/${id}`, updatedDashboard)
			.then((response) => {
				setAlert({ alertMessage: "successful" });
				navigate(-1);
			})
			.catch((error) => {
				setAlert({ alertMessage: "unsuccessful" });
			});
	};

	return (
		<div className="create-dashboard-page">
			<Navigation />

			<div className="edit-point-form-wrapper">
				<Form size="large" onSubmit={onSubmit} className="edit-points-form">
					<img src={Hogwarts} alt="edit boards" />
					<h2>Update / Delete Dashboard</h2>

					<span className="edit-board-dropdown-input">
						<input
							className="form-input-field"
							name="name"
							type="text"
							placeholder="Dashboard Name"
							value={dashboard.name}
							onChange={handleChanges}
						/>

						<TextArea
							className="form-text-field"
							name="description"
							type="textarea"
							placeholder="Description"
							value={dashboard.description}
							onChange={handleChanges}
						/>
					</span>

					<div className="edit-points-back-update-delete">
						<Link to={`/boards/${id}`}>
							<Button animated>
								<Button.Content hidden>Back</Button.Content>
								<Button.Content visible>
									<Icon name="arrow left" />
								</Button.Content>
							</Button>
						</Link>

						<Button color="green" type="submit" animated="fade">
							<Button.Content visible>Update Board Details</Button.Content>
							<Button.Content hidden>Deetum Updatum!</Button.Content>
						</Button>

						<Button onClick={onDelete} animated="vertical">
							<Button.Content hidden>Delete</Button.Content>
							<Button.Content visible>
								<Icon name="trash" />
							</Button.Content>
						</Button>
					</div>
				</Form>

				{alert.alertMessage === "successful" && <CreateBoardSuccessMessage />}
				{alert.alertMessage === "unsuccessful" && <CreateBoardErrorMessage />}
			</div>

			<Footer />
		</div>
	);
}
