import React, { useRef, useEffect } from "react";

function Peepers() {
	const leftEyeRef = useRef(null);
	const rightEyeRef = useRef(null);
	const eyePositionRef = useRef(null);

	useEffect(() => {
		function handleMouseMove(event) {
			const eyePosition = eyePositionRef.current;
			if (!eyePosition) return;
		  
			const x = eyePosition.left + eyePosition.width / 2 + window.scrollX;
			const y = eyePosition.top + eyePosition.height / 2 + window.scrollY;
		  
			const radLeft = Math.atan2(
			  event.pageX - x,
			  event.pageY - y
			);
			const rotLeft = radLeft * (180 / Math.PI) * -1 + 180;
			leftEyeRef.current.style.transform = `rotate(${rotLeft}deg)`;
		  
			const radRight = Math.atan2(
			  event.pageX - (x + eyePosition.width / 4),
			  event.pageY - y
			);
			const rotRight = radRight * (180 / Math.PI) * -1 + 180;
			rightEyeRef.current.style.transform = `rotate(${rotRight}deg)`;
		  }
		  

		const leftEye = leftEyeRef.current;
		const rightEye = rightEyeRef.current;
		if (!leftEye || !rightEye) return;

		const eyePosition = {
			left: leftEye.offsetLeft,
			top: leftEye.offsetTop,
			width: leftEye.offsetWidth,
			height: leftEye.offsetHeight,
		};
		eyePositionRef.current = eyePosition;

		window.addEventListener("mousemove", handleMouseMove);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	return (
		<section className="move-area">
			<div className="container">
				<div className="eye" ref={leftEyeRef}></div>
				<div className="eye" ref={rightEyeRef}></div>
			</div>
		</section>
	);
}

export default Peepers;
