import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";

import Navigation from "./Navigation";
import PointsProgress from "./PointsProgress";
import AddPoints from "./AddPoints";
import ShareButton from "./ShareButton";
import PointsCard from "./PointsCard";
import Footer from "./Footer";

function BoardDashboard() {
	const [points, setPoints] = useState([]);
	const [board, setBoard] = useState({});
	const location = useLocation();
	const board_id = location.pathname.split("/").pop();

	useEffect(() => {
		loadPoints(board_id);
		loadBoard(board_id);
	}, [board_id]);

	function loadPoints(board_id) {
		axiosWithAuth()
			.get(`/api/rewards/board/${board_id}/points`)
			.then((res) => {
				if (typeof res.data === "object") {
					const pointsArray = Object.values(res.data);
					setPoints(pointsArray);
				} else {
					setPoints([]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function loadBoard(board_id) {
		axiosWithAuth()
			.get(`/api/dashboards/board/${board_id}`)
			.then((res) => {
				setBoard(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<div className="dashboard-container">
			<Navigation />
			<div className="points-settings-wrapper">
				<div className="board-details">
					<h1 className="board-name">{board.name}</h1>
					<p className="board-description">{board.description}</p>
					<div className="points-board-nav">
						<Link to="/dashboard">
							<button className="back-to-main-dashboard" size="large">
								Back To Main Dashboard
							</button>
						</Link>

						<Link to={`/edit-board/${board_id}`}>
							<button className="board-settings" size="large">
								Board Settings
							</button>
						</Link>
					</div>
					<PointsProgress board_id={board_id} />
				</div>
				<div className="add-points-section">
				<AddPoints board_id={board_id} />
				<ShareButton/>
				</div>
			</div>
			<div className="board-points-container">
				{points.length > 0 ? (
					points.map((points) => <PointsCard key={points.id} point={points} />)
				) : (
					<div className="board-button">
						<h2 className="no-points-title">No points to display?</h2>
						<p className="no-points-message">
							That's like showing up to a Quidditch match without a broomstick!
							Or like trying to catch a Snitch with your eyes closed! It's okay
							though, we won't judge. Maybe it's time to get creative and come
							up with some new ways to earn points. How about giving out points
							for the most creative hat, or the best dance moves during break
							time? The possibilities are endless, so don't be discouraged. Who
							needs points when you have a good sense of humor and a wand at the
							ready?
						</p>
					</div>
				)}
			</div>
			<Footer />
		</div>
	);
}

export default BoardDashboard;
