import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Grid } from "semantic-ui-react";
import { TextArea } from "semantic-ui-react";

import Hogwarts from "../images/board/hover/icon-2.png";
import CreateBoardSuccessMessage from "../utils/ErrorHandling/CreateBoardSuccessMessage";
import CreateBoardErrorMessage from "../utils/ErrorHandling/CreateBoardErrorMessage";
import Navigation from "./Navigation";
import Footer from "./Footer";

export default function CreateDashboard() {
	const [dashboard, setDashboard] = useState({
		name: "",
		description: "",
	});
	const [alert, setAlert] = useState({
		alertMessage: "",
	});

	const handleChanges = (e) => {
		setDashboard({
			...dashboard,
			[e.target.name]: e.target.value,
		});
	};
	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		const userId = localStorage.getItem("user_id");
		const newDashboard = {
			...dashboard,
			user_id: userId,
		};
		axiosWithAuth()
			.post(`/api/dashboards/user/${userId}/boards`, newDashboard)
			.then((response) => {
				setAlert({ alertMessage: "successful" });
				navigate("/dashboard/");
			})
			.catch((error) => {
				setAlert({ alertMessage: "unsuccessful" });
			});
	};

	return (
		<div className="create-dashboard-page">
			<Navigation />

			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column style={{ maxWidth: 400 }} className="login-form-container">
					<img
						className="hogwarts-flags"
						src={Hogwarts}
						alt="harry-potter-hogwarts-flags"
					/>

					<h2>Create a Dashboard</h2>

					<form onSubmit={onSubmit}>
						<input
							className="form-input-field"
							name="name"
							type="text"
							placeholder="Dashboard Name"
							value={dashboard.name}
							onChange={handleChanges}
						/>

						<TextArea
							className="form-text-field"
							name="description"
							type="textarea"
							placeholder="Description"
							value={dashboard.description}
							onChange={handleChanges}
						/>

						<button className="login-form-button" type="submit">
							SUBMIT
						</button>
					</form>

					{alert.alertMessage === "successful" && <CreateBoardSuccessMessage />}
					{alert.alertMessage === "unsuccessful" && <CreateBoardErrorMessage />}

					<p className="redirect-text">
						<Link className="login-redirect" to="/dashboard">
							Back to Dashboard
						</Link>
					</p>
				</Grid.Column>
			</Grid>
			<Footer />
		</div>
	);
}
