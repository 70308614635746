import React from "react";
import { Link } from "react-router-dom";

import creepyGirl from "../images/board/main/board-image-1.png";
import creepyGirlHover from "../images/board/hover/hogwarts-image-1.png";

import dragon from "../images/board/main/board-image-2.png";
import dragonHover from "../images/board/hover/hogwarts-image-2.png";

import redTheme from "../images/board/main/board-image-3.png";
import redThemeHover from "../images/board/hover/hogwarts-image-3.png";

import gibbous from "../images/board/main/board-image-4.png";
import gibbousHover from "../images/board/hover/hogwarts-image-4.png";

import ohMy from "../images/board/main/board-image-5.png";
import ohMyHover from "../images/board/hover/hogwarts-image-5.png";

import fearNotMyBoy from "../images/board/main/board-image-6.png";
import fearNotMyBoyHover from "../images/board/hover/hogwarts-image-6.png";

import orby from "../images/board/main/board-image-7.png";
import orbyHover from "../images/board/hover/hogwarts-image-7.png";

import genie from "../images/board/main/board-image-8.png";
import genieHover from "../images/board/hover/hogwarts-image-8.png";

import eagle from "../images/board/main/board-image-9.png";
import eagleHover from "../images/board/hover/hogwarts-image-9.png";

import dementor from "../images/board/main/board-image-10.png";
import dementorHover from "../images/board/hover/hogwarts-image-10.png";

import dragonMan from "../images/board/main/board-image-11.png";
import dragonManHover from "../images/board/hover/hogwarts-image-11.png";

import vampire from "../images/board/main/board-image-12.png";
import vampireHover from "../images/board/hover/hogwarts-image-12.png";

const BoardCard = ({ dashboards }) => {
	const imageArray = [
		{
			house: "creepyGirl",
			src: creepyGirl,
			src_hover: creepyGirlHover,
		},
		{
			house: "dragon",
			src: dragon,
			src_hover: dragonHover,
		},
		{
			house: "redTheme",
			src: redTheme,
			src_hover: redThemeHover,
		},
		{
			house: "gibbous",
			src: gibbous,
			src_hover: gibbousHover,
		},
		{
			house: "ohMy",
			src: ohMy,
			src_hover: ohMyHover,
		},
		{
			house: "fearNotMyBoy",
			src: fearNotMyBoy,
			src_hover: fearNotMyBoyHover,
		},
		{
			house: "orby",
			src: orby,
			src_hover: orbyHover,
		},
		{
			house: "genie",
			src: genie,
			src_hover: genieHover,
		},
		{
			house: "eagle",
			src: eagle,
			src_hover: eagleHover,
		},
		{
			house: "dementor",
			src: dementor,
			src_hover: dementorHover,
		},
		{
			house: "dragonMan",
			src: dragonMan,
			src_hover: dragonManHover,
		},
		{
			house: "vampire",
			src: vampire,
			src_hover: vampireHover,
		},
	];

	const { id, name, description } = dashboards;
	const limitedTitle = name.length > 30 ? name.slice(0, 30) + "..." : name;
	const limitedDescription = description
		? description.length > 45
			? description.slice(0, 45) + "..."
			: description
		: "Please add or edit the description on the board.";

	// randomly select an image from the imageArray
	const randomIndex = Math.floor(Math.random() * imageArray.length);
	const selectedImage = imageArray[randomIndex];

	return (
		<div key={id} className="board-points-card">
			<Link to={`/boards/${id}`}>
				<div className="card">
					<div className="wrapper">
						<img
							src={selectedImage.src}
							className="cover-image"
							alt={`${selectedImage.house} crest`}
						/>
						<h2 className="points-board-title">
							<strong className="points-board-title">{limitedTitle}</strong>
						</h2>
						<span className="points-board-description-and-id">
							<p className="points-board-description">{limitedDescription}</p>
							<h2>
								<strong>{id}</strong>
							</h2>
						</span>
					</div>
					<img
						src={selectedImage.src_hover}
						className="character"
						id="board-card-character"
						alt={`${selectedImage.house} crest`}
					/>
				</div>
			</Link>
		</div>
	);
};

export default BoardCard;
