import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Grid } from "semantic-ui-react";

import Logo from "../images/icons/favicon.png";
import Hogwarts from "../images/board/hover/icon-4.png";

import RegisterSuccessMessage from "../utils/ErrorHandling/RegisterSuccessMessage";
import RegisterErrorMessage from "../utils/ErrorHandling/RegisterErrorMessage";
import PasswordErrorMessage from "../utils/ErrorHandling/PasswordErrorMessage";

export default function SignUpForm(props) {
	const [reg, setReg] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
	});

	const [alert, setAlert] = useState({
		alertMessage: "",
	});

	const handleChanges = (e) => {
		setReg({
			...reg,
			[e.target.name]: e.target.value,
		});
	};

	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();

		if (reg.password !== reg.confirmPassword) {
			setAlert({ alertMessage: "unsuccessful" });
			return;
		}
		axiosWithAuth()
			.post("/api/auth/register", reg)
			.then((response) => {
				const tokenPromise = Promise.resolve(
					localStorage.setItem("token", response.data.token)
				);
				const userIdPromise = Promise.resolve(
					localStorage.setItem("user_id", response.data.newUser.id)
				);
				const messagePromise = Promise.resolve(
					localStorage.setItem("message", response.data.message)
				);
				setAlert({ type: "success", message: response.data.message });
				return Promise.all([tokenPromise, userIdPromise, messagePromise]);
			})
			.catch((error) => {
				// ...
				setAlert({ type: "error", message: error.response.data.message });
			})
			.finally(() => {
				navigate("/dashboard");
			});
	};

	return (
		<div className="user-login-page">
			<div className="navigation">
				<Link to="/">
					<img
						className="navigation-logo"
						alt="Hogwarts-Points-Tracker-Logo"
						src={Logo}
					/>
				</Link>
			</div>

			<Grid
				textAlign="center"
				verticalAlign="middle"
				className="add-new-user-form"
			>
				<Grid.Column
					className="register-form-container"
					style={{ maxWidth: 400 }}
				>
					<img
						className="hogwarts-flags"
						src={Hogwarts}
						alt="harry-potter-hogwarts-flags"
					/>

					<h2>Create an Account</h2>

					<form onSubmit={onSubmit}>
						<input
							className="form-input-field"
							type="text"
							name="first_name"
							placeholder="First Name"
							onChange={handleChanges}
						/>

						<input
							className="form-input-field"
							type="text"
							name="last_name"
							placeholder="Last Name"
							onChange={handleChanges}
						/>

						<input
							className="form-input-field"
							type="email"
							name="email"
							placeholder="Email"
							onChange={handleChanges}
						/>

						<input
							className="form-input-field"
							type="password"
							name="password"
							placeholder="Password"
							onChange={handleChanges}
						/>

						<input
							className="form-input-field"
							type="password"
							name="confirmPassword"
							placeholder="Confirm Password"
							onChange={handleChanges}
						/>

						<button className="login-form-button">REGISTER</button>
					</form>

					{alert.alertMessage === "successful" ? (
						<RegisterSuccessMessage />
					) : null}
					{alert.alertMessage === "unsuccessful" ? (
						<RegisterErrorMessage />
					) : null}
					{alert.alertMessage === "unsuccessful" ? (
						<PasswordErrorMessage />
					) : null}

					<p className="redirect-text">
						Already Have An Account? &nbsp;
						<Link to="/login" className="login-redirect">
							<p>
								<strong>Login</strong>
							</p>
						</Link>
					</p>
				</Grid.Column>
			</Grid>
		</div>
	);
}
