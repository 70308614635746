import React, { useState, useEffect } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Button, Input, Dropdown } from "semantic-ui-react";

const AddPoints = ({ board_id }) => {
	const [points, setPoints] = useState({ house: "", amount: "" });
	const [selectedHouse, setSelectedHouse] = useState(null);

	const handleChanges = (event) => {
		setPoints({
			...points,
			[event.target.name]: event.target.value,
		});
	};

	const handleDropdownChange = (event, data) => {
		setSelectedHouse(data.value);
		setPoints({
			...points,
			house: data.value,
		});
	};

	useEffect(() => {
		const id = localStorage.getItem("user_id");
		axiosWithAuth()
			.get(`/api/rewards/users/${id}/points`)
			.then((res) => {
				if (!res.data || typeof res.data !== "object") {
					console.log("Invalid data format.");
				} else {
					setPoints(res.data);
				}
			})
			.catch((error) => {
				console.log(
					error.response?.data?.error || "Unexpected error occurred."
				);
			});
	}, []);

	const onSubmit = (e) => {
		if (points.amount < 0) {
			console.log("Points cannot be negative.");
			return;
		}
		const id = localStorage.getItem("user_id");
		axiosWithAuth()
			.post(`/api/rewards/users/${id}/points`, { ...points, board_id })
			.then(() => {
				setPoints({
					house: "",
					amount: "",
				});
			})
			.catch((error) => {
				console.log(
					error.response?.data?.error ||
						"There was an error adding the requested reward."
				);
			});
	};

	const options = [
		{
			key: "Gryffindor",
			text: "Gryffindor",
			value: "Gryffindor",
		},
		{ key: "Hufflepuff", text: "Hufflepuff", value: "Hufflepuff" },
		{ key: "Ravenclaw", text: "Ravenclaw", value: "Ravenclaw" },
		{ key: "Slytherin", text: "Slytherin", value: "Slytherin" },
	];

	return (
		<div>
			<h2>ADD POINTS</h2>
			<p className="add-point-board-description">
				Psst! Board Master, if you add points here, you'll make the Hogwarts
				house elves happier than a house-elf with a sock on its birthday!
			</p>
			<form onSubmit={onSubmit} className="add-points-form">
				<Dropdown
					placeholder="Select House Name"
					value={selectedHouse}
					selection
					options={options}
					onChange={handleDropdownChange}
					className="submitAmount"
				/>

				<Input
					className="submitAmount"
					type="number"
					name="amount"
					value={points.amount}
					placeholder="Add Points"
					onChange={handleChanges}
				/>

				<Button
					className="submit-points"
					color="blue"
					size="large"
					type="submit"
				>
					Submit
				</Button>
			</form>
		</div>
	);
};

export default AddPoints;
