import React from "react";
import { Icon } from "semantic-ui-react";

const SocialLinks = () => {
	return (
		<div className="social-links-container">
			<a
				href="https://instagram.com/roywakumelojr"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Icon name="instagram" inverted size="large" />
			</a>
			<a
				href="https://twitter.com/roywakumelojr"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Icon name="twitter" inverted size="large" />
			</a>
			<a
				href="https://www.linkedin.com/in/roywakumelojr/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Icon name="linkedin" inverted size="large" />
			</a>
			<a
				href="https://github.com/roywakumelojr"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Icon name="github" inverted size="large" />
			</a>
			<a
				href="https://roywakumelojr.com/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Icon name="globe" inverted size="large" />
			</a>
		</div>
	);
};

export default SocialLinks;
