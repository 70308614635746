import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import Hufflepuff from "../images/crests/Hufflepuff-Winner.png";
import HufflepuffHover from "../images/crests/Hufflepuff.png";

import Slytherin from "../images/crests/Slytherin-Winner.png";
import SlytherinHover from "../images/crests/Slytherin.gif";

import Gryffindor from "../images/crests/Gryffindor-Winner.png";
import GryffindorHover from "../images/crests/Gryffindor.png";

import Ravenclaw from "../images/crests/Ravenclaw-Winner.png";
import RavenclawHover from "../images/crests/Ravenclaw.png";

const PointsCard = ({ point }) => {
	const crestArray = [
		{
			house: "Hufflepuff",
			src: Hufflepuff,
			src_hover: HufflepuffHover,
		},
		{
			house: "Slytherin",
			src: Slytherin,
			src_hover: SlytherinHover,
		},
		{
			house: "Gryffindor",
			src: Gryffindor,
			src_hover: GryffindorHover,
		},
		{
			house: "Ravenclaw",
			src: Ravenclaw,
			src_hover: RavenclawHover,
		},
	];

	return (
		<>
			{point.map((point) => {
				const { id, house, amount, created_at } = point;
				const selectedCrest = crestArray.find(
					(crest) => crest.house.toLowerCase() === house.toLowerCase()
				);
				const formattedDate = moment(created_at).format("MMMM Do YYYY", "h:mm");

				return (
					<div key={id} className="board-points-card">
						<Link to={`/edit-point/${id}`}>
							<div className="card">
								<div className="wrapper">
									<img
										src={selectedCrest?.src}
										className="cover-image"
										alt={`${house} crest`}
									/>
									<h2 className="points-board-title">
										<strong className="points-board-title">{`${amount} Points`}</strong>
									</h2>
									<p className="points-board-description">{`Earned: ${formattedDate}`}</p>
								</div>
								<img
									src={selectedCrest?.src_hover}
									className="character"
									id="points-card-character"
									alt={`${house} crest`}
								/>
							</div>
						</Link>
					</div>
				);
			})}
		</>
	);
};

export default PointsCard;
