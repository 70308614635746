import React from "react";
import { Icon } from "semantic-ui-react";

const ShareButton = ({ title, url }) => {
	const handleClick = (socialMedia) => {
		const shareUrl = getShareUrl(socialMedia, title, url);
		window.open(shareUrl, "_blank");
	};

	const getShareUrl = (socialMedia) => {
		const url = "https://hogwartspointstracker.com";
		const title = "Hogwarts Points Tracker";

		switch (socialMedia) {
			case "twitter":
				return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
					url
				)}&text=${encodeURIComponent(title)}`;
			case "facebook":
				return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
					url
				)}`;
			case "linkedin":
				return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
					url
				)}&title=${encodeURIComponent(title)}`;
			case "instagram":
				return `https://www.instagram.com/share?url=${encodeURIComponent(
					url
				)}&title=${encodeURIComponent(title)}`;
			case "tiktok":
				return `https://www.tiktok.com/share?url=${encodeURIComponent(
					url
				)}&title=${encodeURIComponent(title)}`;
			default:
				return "";
		}
	};

	return (
		<div>
			<Icon
				inverted
				name="twitter"
				size="big"
				link
				onClick={() => handleClick("twitter")}
			/>
			<Icon
				inverted
				name="facebook"
				size="big"
				link
				onClick={() => handleClick("facebook")}
			/>
			<Icon
				inverted
				name="linkedin"
				size="big"
				link
				onClick={() => handleClick("linkedin")}
			/>
			<Icon
				inverted
				name="instagram"
				size="big"
				link
				onClick={() => handleClick("instagram")}
			/>
		</div>
	);
};

export default ShareButton;
