import React from "react";
import { Message } from "semantic-ui-react";

const RegisterErrorMessage = () => {
  return (
    <Message negative>
      <Message.Header>Registration Error!</Message.Header>
      <p>Please double check all your provided information and try again.</p>
    </Message>
  );
};

export default RegisterErrorMessage;
