import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Grid } from "semantic-ui-react";

import Logo from "../images/icons/favicon.png";
import Hogwarts from "../images/board/hover/icon-3.png";
import LoginSuccessMessage from "../utils/ErrorHandling/LoginSuccessMessage";
import LoginErrorMessage from "../utils/ErrorHandling/LoginErrorMessage";

export default function Login(props) {
	const [auth, setAuth] = useState({
		email: "",
		password: "",
	});

	const [alert, setAlert] = useState({
		alertMessage: "",
	});

	const handleChanges = (e) => {
		setAuth({
			...auth,
			[e.target.name]: e.target.value,
		});
	};
	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		axiosWithAuth()
			.post("/api/auth/login", auth)
			.then((response) => {
				localStorage.setItem("token", response.data.token);
				localStorage.setItem("user_id", response.data.user.id);
				localStorage.setItem("message", response.data.message);

				navigate("/dashboard");
				setAlert({ alertMessage: "successful" });
			})
			.catch((error) => {
				setAlert({ alertMessage: "unsuccessful" });
			});
	};

	return (
		<div className="user-login-page">
			<div className="navigation">
				<Link to="/">
					<img
						className="navigation-logo"
						alt="Hogwarts-Points-Tracker-Logo"
						src={Logo}
					/>
				</Link>
			</div>

			<Grid textAlign="center" verticalAlign="middle">
				<Grid.Column style={{ maxWidth: 400 }} className="login-form-container">
					<img
						className="hogwarts-flags"
						src={Hogwarts}
						alt="harry-potter-hogwarts-flags"
					/>

					<h2>Have an Account?</h2>

					<form onSubmit={onSubmit}>
						<input
							className="form-input-field"
							name="email"
							type="email"
							placeholder="Email"
							value={auth.email}
							onChange={handleChanges}
						/>

						<input
							className="form-input-field"
							name="password"
							type="password"
							placeholder="Password"
							value={auth.password}
							onChange={handleChanges}
						/>

						<button className="login-form-button" type="submit">
							SIGN IN
						</button>
					</form>

					{alert.alertMessage === "successful" && <LoginSuccessMessage />}
					{alert.alertMessage === "unsuccessful" && <LoginErrorMessage />}

					<p className="redirect-text">
						Not A Member? &nbsp;
						<Link className="login-redirect" to="/register">
							Sign Up
						</Link>
					</p>
				</Grid.Column>
			</Grid>
		</div>
	);
}
