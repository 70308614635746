import React from "react";
import { Message } from "semantic-ui-react";

const PasswordErrorMessage = () => {
	return (
		<Message negative>
			<Message.Header>Oops! Mischief Managed!</Message.Header>
			<p>
				Your passwords seem to have played a trick on you! They don't match.
				Please try again with matching passwords.
			</p>
		</Message>
	);
};

export default PasswordErrorMessage;
