import React from "react";
import { Message } from "semantic-ui-react";

const RegisterSuccessMessage = () => {
  return (
    <Message positive>
      <Message.Header>Registered Successfully</Message.Header>
      <p>You are now being redirected to the login page...</p>
    </Message>
  );
};

export default RegisterSuccessMessage;
