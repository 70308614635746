import React from "react";
import SocialLinks from "./SocialLinks";
const currentYear = new Date().getFullYear();

const Footer = () => {
	return (
		<div className="footer-container">
			<p className="credit">
				&copy; {currentYear} Web Application developed by
				<a
					href="https://roywakumelojr.com"
					rel="noopener noreferrer"
					target="_blank"
				>
					&nbsp;Roy Wakumelo Jr
				</a>
			</p>
			<SocialLinks />
		</div>
	);
};

export default Footer;
