import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Button, Icon } from "semantic-ui-react";
import { Triangle } from "react-loader-spinner";

import BoardCard from "./BoardCard";
import Peepers from "./Peepers";
import Footer from "./Footer";
import Navigation from "./Navigation";

import DashboardProfileImage from "../images/board/hover/howdy-2.png";

function Dashboard() {
	const [dashboards, setDashboards] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		loadBoards();
	}, []);

	function loadBoards() {
		const id = localStorage.getItem("user_id");
		axiosWithAuth()
			.get(`/api/dashboards/user/${id}/boards`)
			.then((res) => {
				setDashboards(res.data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setError(true);
				setIsLoading(false);
				if (err.response.status === 500) {
					setError(
						"Oops, it looks like there's an issue with the server and no boards were returned. Try again later!"
					);
				} else {
					setError("You don't have any boards yet.");
				}
			});
	}

	return (
		<div className="dashboard-container">
			<Navigation />

			<div className="user-dashboard">
				<div className="left-boards-container">
					<span>
						<img
							className="dashboard-profile-image"
							src={DashboardProfileImage}
							alt="dashboard wizard"
						/>
					</span>

					<span>
						<Peepers />
						<h2 className="quidditch-game-board-title">
							Welcome to Your <br /> Quidditch Game Plan Board
						</h2>
						<p className="quidditch-game-board-description">
							Here are some tips to help you win your next match: first, make
							sure to wear your lucky underwear (preferably washed, but we won't
							judge). Second, don't forget to bring your broomstick - flying on
							a vacuum cleaner won't cut it, no matter how powerful it is.
							Third, try not to fall off your broomstick mid-air - it's not a
							good look. And finally, if you see a snitch flying by, don't
							forget to scream like a banshee and chase after it with reckless
							abandon. Good luck out there, and remember, winning isn't
							everything - it's the only thing. Just kidding, have fun and don't
							take it too seriously - it's just a game, after all.
						</p>
						<Link to="/create-dashboard" className="create-new-board-plus">
							<h2 className="create-board-title">Create a New Board</h2>
							<Icon inverted circular color="grey" name="plus" size="big" />
						</Link>
					</span>
				</div>

				<div className="current-boards">
					<div className="right-boards-container">
						{isLoading ? (
							<div className="loader-container">
								<Triangle
									type="ThreeDots"
									color="#f5a623"
									height={50}
									width={50}
								/>
							</div>
						) : error ? (
							<div className="no-dashboards">
								{error === true ? (
									<Icon
										className="boards-error-bug"
										name="bug"
										size="huge"
										color="orange"
									/>
								) : (
									<p className="dashboard-error-messages">{error}</p>
								)}
								{error === true ? (
									<p className="dashboard-error-messages">
										Oops, it looks like there's an issue with the server and no
										boards were returned. Try again later!
									</p>
								) : (
									<Link to="/create-dashboard">
										<Button color="orange">Create Your First Dashboard</Button>
									</Link>
								)}
							</div>
						) : dashboards.length === 0 ? (
							<div className="no-dashboards">
								<p className="dashboard-error-messages">
									You don't have any boards yet.
								</p>
								<Link to="/create-dashboard">
									<Button color="orange">Create Your First Dashboard</Button>
								</Link>
							</div>
						) : null}
					</div>
					<div className="board-elements">
						<div className="dashboard-elements">
							{dashboards.map((dashboards) => (
								<BoardCard key={dashboards.id} dashboards={dashboards} />
							))}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Dashboard;
