import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { Rings } from "react-loader-spinner";

import Logo from "../images/icons/favicon.png";
import HeroImage from "../images/landscape-backgrounds/hero-image.png";

import WinningHouse from "./WinningHouse";
import Footer from "./Footer";

function LandingPage() {
	return (
		<div className="landing-page-container">
			<div className="navigation">
				<Link to="/">
					<img
						className="navigation-logo"
						alt="Hogwarts-Points-Tracker-Logo"
						src={Logo}
					/>
				</Link>
				<Button.Group className="navigation-buttons">
					<Link className="sign-in-button" to={"/login"}>
						<Button primary>
							<Icon loading name="spinner" />
							Sign In
						</Button>
					</Link>

					<Button.Or />

					<Link className="sign-up-button" to={"/register"}>
						<Button color="orange" className="register-button">
							<Rings
								height="30"
								width="30"
								color="white"
								radius="6"
								wrapperStyle={{}}
								visible={true}
								ariaLabel="rings-loading"
							/>
							Sign Up
						</Button>
					</Link>
				</Button.Group>
			</div>

			<div className="hero-section">
				<span className="left-cta">
					<img
						className="hero-image"
						alt="Hogwarts-Points-Tracker-Hero-cover"
						src={HeroImage}
					/>
					<p className="project-intro">
						Don't be a Muggle - join the Hogwarts Points Tracking Web App and
						help your house become the most knowledgeable and dedicated in all
						of Hogwarts! With points for each class you attend, it's a fun and
						interactive way to stay connected to the magical world of Harry
						Potter. So what are you waiting for? Start earning points and show
						your house who's boss!
					</p>
				</span>

				<span className="right-cta">
					<WinningHouse />
				</span>
			</div>
			<Footer />
		</div>
	);
}

export default LandingPage;
