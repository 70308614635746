import React from "react";
import { Message } from "semantic-ui-react";

const LoginErrorMessage = () => {
	return (
		<Message negative>
			<Message.Header>Invalid Email or Password!</Message.Header>
			<p>
				Hogwarts has already had enough Dark Magic, we can't have you using
				invalid email and password combinations! Please double-check and try
				again!
			</p>
		</Message>
	);
};

export default LoginErrorMessage;
