import React, { useState, useEffect } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";

import Hufflepuff from "../images/crests/Hufflepuff-Winner.png";
import HufflepuffHover from "../images/crests/Hufflepuff.png";

import Slytherin from "../images/crests/Slytherin-Winner.png";
import SlytherinHover from "../images/crests/Slytherin.gif";

import Gryffindor from "../images/crests/Gryffindor-Winner.png";
import GryffindorHover from "../images/crests/Gryffindor.png";

import Ravenclaw from "../images/crests/Ravenclaw-Winner.png";
import RavenclawHover from "../images/crests/Ravenclaw.png";

function WinningHouse() {
	const [housePoints, setHousePoints] = useState({});

	useEffect(() => {
		const houses = ["Hufflepuff", "Slytherin", "Gryffindor", "Ravenclaw"];

		async function loadHousePoints(house) {
			try {
				const response = await axiosWithAuth().get(
					`/api/total-points/${house}TotalPoints`
				);
				return {
					house,
					points: parseInt(response.data[0][`${house}TotalPoints`]),
				};
			} catch (error) {
				console.log({
					error: `There was an error getting the current score for ${house}`,
				});
				return {
					house,
					points: 0,
				};
			}
		}

		async function fetchAllHousePoints() {
			const fetchedHousePoints = await Promise.all(
				houses.map((house) => loadHousePoints(house))
			);

			const housePointsObject = fetchedHousePoints.reduce((acc, curr) => {
				acc[curr.house] = curr.points;
				return acc;
			}, {});

			setHousePoints(housePointsObject); // This is where 'setHousePoints' is used
		}

		fetchAllHousePoints();
	}, []);

	const crestArray = [
		{
			house: "Hufflepuff",
			points: housePoints.Hufflepuff,
			src: Hufflepuff,
			src_hover: HufflepuffHover,
		},
		{
			house: "Slytherin",
			points: housePoints.Slytherin,
			src: Slytherin,
			src_hover: SlytherinHover,
		},
		{
			house: "Gryffindor",
			points: housePoints.Gryffindor,
			src: Gryffindor,
			src_hover: GryffindorHover,
		},
		{
			house: "Ravenclaw",
			points: housePoints.Ravenclaw,
			src: Ravenclaw,
			src_hover: RavenclawHover,
		},
	];

	const highestCrest = crestArray.reduce((highest, crest) => {
		if (!highest.points || crest.points > highest.points) {
			return crest;
		}
		return highest;
	}, {});

	return (
		<div className="current-leading-house">
			<div className="card">
				<div className="wrapper">
					<img src={highestCrest.src} className="cover-image" alt="Mythrill" />
				</div>
				<img
					src={highestCrest.src_hover}
					className="character"
					alt="Mythrill"
				/>
			</div>
			<h2>
				Current <br />
				Leading House!
			</h2>
		</div>
	);
}

export default WinningHouse;
