import React, { useState, useEffect } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { Form, Button, Input, Dropdown, Icon } from "semantic-ui-react";

import EditPointsImage from "../images/board/hover/hogwarts-icon-2.png";

const EditPoints = () => {
	const navigate = useNavigate();
	const id = useParams().point_id;
	const [selectedHouse, setSelectedHouse] = useState(null);
	const [point, setPoint] = useState({
		id: "",
		house: "",
		amount: "",
	});

	const handleChange = (e) => {
		setPoint({
			...point,
			[e.target.name]: e.target.value,
		});
	};

	const handleDropdownChange = (event, data) => {
		setSelectedHouse(data.value);
		setPoint({
			...point,
			house: data.value,
		});
	};

	const SaveChange = (event) => {
		event.preventDefault();
		axiosWithAuth()
			.put(`/api/rewards/points/${id}`, point)
			.then((response) => {
				navigate(-1);
			})
			.catch((error) =>
				console.log(
					"The was an error updating the requested information",
					error
				)
			);
	};

	const DeleteReward = (event) => {
		axiosWithAuth()
			.delete(`/api/rewards/points/${id}`, point)
			.then((response) => {
				navigate(-1);
			})
			.catch((error) =>
				console.log(
					"There was an error deleting the requested points entry.",
					error
				)
			);
	};

	useEffect(() => {
		axiosWithAuth()
			.get(`/api/rewards/points/${id}`)
			.then((response) => {
				setPoint({
					id: id,
					house: response.data.house,
					amount: response.data.amount,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id]);

	const options = [
		{
			key: "Gryffindor",
			text: "Gryffindor",
			value: "Gryffindor",
		},
		{ key: "Hufflepuff", text: "Hufflepuff", value: "Hufflepuff" },
		{ key: "Ravenclaw", text: "Ravenclaw", value: "Ravenclaw" },
		{ key: "Slytherin", text: "Slytherin", value: "Slytherin" },
	];

	return (
		<div className="edit-point-container">
			<Navigation />

			<div className="edit-point-form-wrapper">
				<Form size="large" onSubmit={SaveChange} className="edit-points-form">
					<img src={EditPointsImage} alt="edit points" />
					<h2>Update / Delete Points</h2>

					<span className="edit-points-dropdown-input">
						<Dropdown
							className="points-entry"
							placeholder={point.house}
							value={selectedHouse}
							selection
							options={options}
							onChange={handleDropdownChange}
						/>

						<Input
							className="points-entry"
							type="number"
							name="amount"
							placeholder="Points"
							value={point.amount}
							onChange={handleChange}
						/>
					</span>

					<div className="edit-points-back-update-delete">
						<Button animated>
							<Button.Content hidden>Back</Button.Content>
							<Button.Content visible>
								<Icon name="arrow left" />
							</Button.Content>
						</Button>

						<Button color="green" type="submit" animated="fade">
							<Button.Content visible>Update Point Details</Button.Content>
							<Button.Content hidden>Deetum Updatum!</Button.Content>
						</Button>

						<Button onClick={DeleteReward} animated="vertical">
							<Button.Content hidden>Delete</Button.Content>
							<Button.Content visible>
								<Icon name="trash" />
							</Button.Content>
						</Button>
					</div>
				</Form>
			</div>
			<Footer />
		</div>
	);
};

export default EditPoints;
