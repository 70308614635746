import React from "react";
import { Message } from "semantic-ui-react";

const CreateBoardSuccessMessage = () => {
	return (
		<Message positive>
			<Message.Header>Successful</Message.Header>
			<p>
				YCongrats, you've leveled up! Prepare to blast off to your dashboard in
				3...2...1... ignition!
			</p>
		</Message>
	);
};

export default CreateBoardSuccessMessage;
