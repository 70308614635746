import React from "react";
import { Message } from "semantic-ui-react";

const CreateBoardErrorMessage = () => {
	return (
		<Message negative>
			<Message.Header>Unsuccessful!</Message.Header>
			<p>
				Oopsie! Looks like the board wasn't feeling very creative today and just
				wouldn't come into existence. Please give it some time to think and try
				again later!
			</p>
		</Message>
	);
};

export default CreateBoardErrorMessage;
